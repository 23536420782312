@keyframes slideOdd {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slideEven {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(100%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 0;
    pointer-events: none;
  }
}

.past-bets {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.past-bets > * {
  min-width: 60px;
  font-variant-numeric: tabular-nums;
  will-change: transform;
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  animation-timing-function: ease-out;
  position: absolute;
}

.past-bets > *:nth-child(2n) {
  animation-name: slideEven;
}

.past-bets > *:nth-child(odd) {
  animation-name: slideOdd;
}

.past-bets.full > *:last-child {
  animation-name: slideOut;
}
